.root {
    max-width: 600px;
    margin: 10px;
}

.preview {
    max-height: 100px;
}

.error {
    color: #ff0000;
}