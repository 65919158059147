.App {
  text-align: center;
  font-family: Inter;
}

h1,
h2 {
  margin: 0.5em 0;
}

.landing a .tile-link {
  border: 2px solid #000000;
  border-radius: 10px;
  width: 200px;
  height: 200px;
  line-height: 200px;
}

.landing .tile-link:hover {
  background-color: #c0c0c0;
}

.landing a {
  text-decoration: none;
  color: #000000;
  text-transform: capitalize;
  width: 200px;
  height: 200px;
}

.tile-container {
  margin-top: 2em;
}